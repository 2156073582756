<template>
  <VContent>
    <template slot="right">
      <span>قروض المصرف</span>
    </template>
    <template slot="left">
      <b-button variant="info" size="md" class="text-light" v-b-modal.loan-modal
        >اضافة</b-button
      >
    </template>
    <template slot="content">
      <b-table
        :items="items"
        :fields="fields"
        :tbody-transition-props="transProps"
        tbody-tr-class="text-muted"
        responsive
        table-class="table-font h-100"
      >
        <template #cell(id)="data">
          {{ (page - 1) * 10 + data.index + 1 }}
        </template>
        <template v-slot:cell(type)="{ item }">
          <div class="p-2 rounded-pill purple">{{ getType(item.type) }}</div>
        </template>
        <template v-slot:cell(benefit)="{ item }">
          <div class="p-2 rounded-pill orange">{{ item.benefit }}</div>
        </template>
        <template v-slot:cell(period)="{ item }">
          <div class="p-2 rounded-pill green">{{ item.period }}</div>
        </template>
        <template v-slot:cell(created_at)="{ item }">{{
          new Date(item.created_at).toISOString().slice(0, 10)
        }}</template>
        <template v-slot:cell(actions)="{ item }">
          <b-dropdown size="lg" variant="link" no-caret>
            <template #button-content>
              <b-icon icon="three-dots" animation="cylon"></b-icon>
            </template>
            <b-dropdown-item @click="getByID(item.id, true)">
              <b-icon
                icon="file-earmark-medical"
                scale="0.5"
                class="ml-2"
              ></b-icon>
              عرض</b-dropdown-item
            >
            <b-dropdown-item @click="getByID(item.id, false)">
              <b-icon icon="pencil-square" scale="0.5" class="ml-2"></b-icon>
              تعديل</b-dropdown-item
            >
            <b-dropdown-item @click="remove(item.id)">
              <b-icon icon="archive" scale="0.5" class="ml-2"></b-icon>
              حذف</b-dropdown-item
            >
          </b-dropdown>
        </template>
      </b-table>
    </template>
    <template slot="modals">
      <LoanModal :readonly="readonly" @close="readonly = false"></LoanModal>
    </template>
    <template slot="bottom">
      <b-pagination
        v-model="page"
        :current-page="meta.current_page"
        :per-page="meta.per_page"
        :total-rows="meta.last_page * meta.per_page"
      ></b-pagination>
    </template>
  </VContent>
</template>

<script>
import LoanModal from "./components/loanModal";
import {
  successAlert,
  errorAlert,
  showAlertWithConfirm,
  ImageURL,
} from "@/utils/helper";

export default {
  components: { LoanModal },
  data() {
    return {
      page: 1,
      readonly: false,
      transProps: {
        name: "flip-list",
      },
      fields: [
        {
          key: "id",
          label: "أ",
          sortable: true,
        },
        {
          key: "name",
          label: "عنوان القرض",
          sortable: true,
        },
        {
          key: "type",
          label: "نوع القرض",
          sortable: true,
        },
        {
          key: "limits",
          label: "حدود القرض",
          sortable: true,
        },
        {
          key: "benefit",
          label: "نسبة الفائدة",
          sortable: true,
        },
        {
          key: "period",
          label: "المدة",
          sortable: true,
        },
        {
          key: "created_at",
          label: "تاريخ الأضافة",
          sortable: true,
        },
        {
          key: "actions",
          label: "العمليات",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.banks.loans.list;
    },
    meta() {
      return this.$store.state.banks.loans.meta;
    },
    types() {
      return this.$store.state.banks.loans.types;
    },
  },
  watch: {
    page() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.$store.dispatch("banks/loans/get", this.page);
    },
    getByID(id, status) {
      this.$store
        .dispatch("banks/loans/show", id)
        .then(() => {
          this.readonly = status;
          this.$bvModal.show("loan-modal");
        })
        .catch(() => errorAlert());
    },
    remove(id) {
      showAlertWithConfirm().then((response) => {
        if (response.isConfirmed) {
          this.$store
            .dispatch("banks/loans/delete", id)
            .then(() => {
              successAlert();
              this.$store.commit("banks/loans/DELETE_LOAN", id);
            })
            .catch((error) =>
              errorAlert(
                error.response ? error.response.data.message : "فشل الحذف"
              )
            );
        }
      });
    },
    getType(type) {
      return this.types.find((x) => x.value === type).text;
    },
    ImageURL(item) {
      return item.image ? item.image : ImageURL("user.jpg");
    },
  },
};
</script>

<style lang="scss">
td[aria-colindex="2"] {
  text-align: right;
}
td[aria-colindex="3"] {
  direction: ltr;
}
</style>
